
import clonedeep from "lodash.clonedeep";
import { mixins } from "vue-class-component";
import { Component } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from "vuex-class";

import AgencyPreview from "@/components/AgencyPreview.vue";
import AgencyForm from "@/components/agency/AgencyForm.vue";
import AgencyRead from "@/components/agency/AgencyRead.vue";
import { ROUTES } from "@/data";
import TitleManager from "@/mixins/TitleManager.vue";
import { IAgencyDB, IUser, LabelValue, StoreAction, StoreMutation } from "@/types";

@Component({
    name: "AgencyProfile",
    components: {AgencyPreview, AgencyRead, AgencyForm}
})
export default class AgencyProfile extends mixins(TitleManager) {
    @State('currentUser') currentUser!: IUser;

    @Action('actions/loadAgency') loadAgency!: StoreAction;
    @Action('actions/editAgency') editAgency!: StoreAction;

    @Getter('jobOptions') jobOptions!: LabelValue[];
    @Getter('businessSectorOptions') businessSectorOptions!: LabelValue[];
    @Getter('isSupervisor') isSupervisor!: boolean;
    @Mutation('addLink') addLink!: StoreMutation;

    agency: IAgencyDB | null = null;
    loading = false;
    innerLoading = false;
    errors:any = {};
    globalErrors:any = {};
    showPreviewAgency = false;
    pageTitle = this.$t('layout.left.agency');

    getBusinessSector(id: string) {
        return this.businessSectorOptions.find((i:LabelValue) => i.value === id);
    }

    getJob(id: string) {
        return this.jobOptions.find((i:LabelValue) => i.value === id);
    }

    NaNOrValue(option: LabelValue) {
        return option && (typeof option.value === "number" && isNaN(option.value) ? option.label : option.value)
    }

    async saveAgencyProfile() {
        this.innerLoading = true;
        this.errors = {};
        this.globalErrors = [];
        try {
            if (this.agency) {
                const survey:any = clonedeep(this.agency.survey);

               if (survey) {
                    if (survey.mainBusinessSectors) {
                        survey.mainBusinessSectors = survey.mainBusinessSectors.map(this.NaNOrValue).filter((a:any) => a);
                    }
                    if (survey.developingBussinessSectors) {
                        survey.developingBussinessSectors = survey.developingBussinessSectors.map(this.NaNOrValue).filter((a:any) => a);
                    }
                    if (survey.jobsRequiringMissions) {
                        survey.jobsRequiringMissions = survey.jobsRequiringMissions.map(this.NaNOrValue).filter((a:any) => a);
                    }
                    if (survey.jobsRequiringWorkers) {
                        survey.jobsRequiringWorkers = survey.jobsRequiringWorkers.map(this.NaNOrValue).filter((a:any) => a);
                    }
                }

                await this.editAgency({
                    agencyId: this.agency._id,
                    data: {
                        "name" : this.agency.name || "",
                        "address": {
                            "name": this.agency.address?.name,
                            "street": this.agency.address?.street,
                            "complement": this.agency.address?.complement,
                            "city": this.agency.address?.city,
                            "zip": this.agency.address?.zip,
                            "countryCode": this.agency.address?.countryCode,
                        },
                        phone: this.agency.phone || "",
                        email: this.agency.email || "",
                        "description" : this.agency.description || "",
                        "numbers" : this.agency.numbers,
                        "logo" : this.agency.logo,
                        "video" : this.agency.video,
                        "siret": this.agency.siret || "",
                        "website": this.agency.website || "",
                        "socialNetworks": this.agency.socialNetworks,
                        "survey": survey,
                        delegationDepartments:this.agency.delegationDepartments
                    }
                });
            }
        } catch (e) {
            console.log(e);
            this.errors = e.errors || [];
            this.globalErrors = e._global || [];
        }
        this.innerLoading = false;
    }

    async init() {
        this.loading = true;
        try {
            this.agency = await this.loadAgency(this.currentUser.agencyId);

            if (this.agency) {
                const survey:any = this.agency.survey;

                if (survey) {
                    if (survey.mainBusinessSectors) {
                        survey.mainBusinessSectors = survey.mainBusinessSectors.map((i:string) => this.getBusinessSector(i));
                    }
                    if (survey.developingBussinessSectors) {
                        survey.developingBussinessSectors = survey.developingBussinessSectors.map((i:string) => this.getBusinessSector(i));
                    }
                    if (survey.jobsRequiringMissions) {
                        survey.jobsRequiringMissions = survey.jobsRequiringMissions.map((i:string) => this.getJob(i));
                    }
                    if (survey.jobsRequiringWorkers) {
                        survey.jobsRequiringWorkers = survey.jobsRequiringWorkers.map((i:string) => this.getJob(i));
                    }
                }

                this.$set(this.agency, 'survey', survey);
                this.addLink({
                    label: this.agency.name,
                    icon: 'agency',
                    route: {name: ROUTES.APP.AGENCYPROFILE},
                    value: this.agency._id
                });
            }
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    beforeMount() {
        this.init();
    }
}
